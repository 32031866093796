<template>
  <div
    id="portfolio"
    class="portfolio"
  >
    <div>
      <h1>{{ data?.title }}</h1>
      <p style="white-space: break-spaces;">{{ data?.description }}</p>
    </div>
    <div class="port-iso">
      <div
        class="iso-all"
        v-if="data?.realisations"
      >
        <div
          v-for="i in 3"
          class="iso-line"
        >
          <div
            v-for="j in 4"
            class="iso-col"
          >
            <template v-for="k in 2">
              <template v-if="data?.realisations[((i - 1) * 2 * 4) + ((j - 1) * 2) + (k - 1)]">
                <div
                  v-if="data?.realisations[((i - 1) * 2 * 4) + ((j - 1) * 2) + (k - 1)].isBigSize"
                  class="iso-item tile1"
                >
                </div>
                <div
                  v-if="!data?.realisations[((i - 1) * 2 * 4) + ((j - 1) * 2) + (k - 1)].isBigSize"
                  class="iso-item tile2"
                  @click="openModal(((i - 1) * 2 * 4) + ((j - 1) * 2) + (k - 1))"
                >
                  <img
                    :src="data?.realisations[((i - 1) * 2 * 4) + ((j - 1) * 2) + (k - 1)].preview.url"
                    alt=""
                    loading="lazy"
                  />
                  <div>{{ data?.realisations[((i - 1) * 2 * 4) + ((j - 1) * 2) + (k - 1)].title }}</div>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
    <Details
      v-if="isOpen"
      @closeModalFromChild="closeModal"
      :data="data?.realisations"
      :currentId="openId"
    />
  </div>
</template>

<script>
import Details from './5bis_Details.vue'

export default {
  name: 'Portfolio',
  props: {
    data: Object
  },
  data() {
    return {
      isOpen: false,
      openId: 1,
    }
  },
  components: {
    Details,
  },
  methods: {
    openModal(id) {
      this.openId = id
      this.isOpen = true
    },
    closeModal(variable) {
      this.isOpen = variable
    }
  }
}
</script>

<style lang="scss">
.portfolio {
  background-color: var(--bg-color-white);

  >div:first-child {
    text-align: left;
    padding: 30px 10px;

    p {
      font-size: 13px;
    }
  }

  .port-iso {
    display: flow-root;
  }

  .port-iso>div>div {
    // background-color: red;
  }

  .iso-all {
    .iso-line {
      width: 100%;
      margin-bottom: -4px;
    }

    .iso-col:nth-child(1),
    .iso-col:nth-child(2),
    .iso-col:nth-child(3),
    .iso-col:nth-child(4) {
      margin-top: -5px;
    }

    .iso-col {
      width: 50%;
      // float: left;
      display: inline-block;
      position: relative;

      .iso-item {
        width: 100%;
        height: 50%;
        display: block;
        position: relative;
        height: 50%;
        display: table;
        cursor: pointer;

        img {
          float: left;
          width: 100%;
        }

        div {
          display: none;
        }
      }

      .iso-item:hover div {
        // transform: scale(0.9);
      }

      .iso-item:hover div {
        box-sizing: border-box;
        top: 8px;
        left: 8px;
        right: 8px;
        bottom: 8px;
        display: block;
        position: absolute;
        background-color: rgba(255, 255, 255, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 4px solid transparent;
      }
    }
  }
}

@media (min-width: $mq-m) {
  .portfolio {
    >div:first-child {
      margin: auto;
      text-align: left;
      max-width: 1200px;
    }

    .iso-all {
      .iso-line {}

      .iso-col:nth-child(3),
      .iso-col:nth-child(4) {
        margin-top: 0px;
      }

      .iso-col {
        width: 25%;

        .iso-item {
          img {}

          div {}
        }

        .iso-item:hover {
          div {}
        }
      }
    }
  }
}
</style>
