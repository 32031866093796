<template>
    <div
        class="testimonials"
        v-if="data?.length"
    >
        <div>
            <Carousel
                :autoPlay="true"
                :pagination="false"
                :draggable="true"
                :autoPlayTime="2000"
                :numberOfItems="data.length"
                :numberOfItemsToShow="5"
                :numberOfItemsToShowCustom="[[1, 1], [559, 3], [879, 5]]"
            >
                <Slide
                    v-for="item in data"
                    v-bind:key="item?.id"
                >
                    <a
                        :href="item?.website"
                        target="_blank"
                    >
                        <img
                            v-if="item?.image?.url"
                            :src="item?.image?.url"
                            :alt="item?.name"
                            loading="lazy"
                        />
                    </a>
                </Slide>
            </Carousel>
        </div>
    </div>
</template>

<script>
import Slide from '@/components/Noice/Slide.vue'
import Carousel from '@/components/Noice/Carousel.vue'

export default {
    components: {
        Slide,
        Carousel
    },
    props: {
        data: Object
    }
}
</script>

<style lang="scss" scoped>
.testimonials {
    .carousel {
        padding: 20px 10px;
        margin: 20px;
    }

    img {
        width: 180px;
    }
}

@media (min-width: $mq-m) {
.testimonials {
    img {
        width: 160px;
    }
}
}
</style>