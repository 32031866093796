<template>
  <SplashScreen v-if="isLoading" />
  <template v-else>
    <Nav :data="data?.menu" />
    <router-view :data="data" />
  </template>
</template>

<script>
import axios from 'axios'
import Nav from '@/views/Nav.vue'
import SplashScreen from '@/views/0_SplashScreen.vue'

export default {
  name: 'HomeView',
  components: {
    Nav,
    SplashScreen
  },
  data() {
    return {
      isLoading: true,
      data: null,
      imageUrl: null,
    }
  },
  methods: {
    async getData() {
      const publicToken = '881f9075af275b89f8404b19b96505839c038334561c0beb1a23a52f331298a268bd55dfb4a9a5781d848006f00359262d808d35e5200355782ba1aff956c390ccbf3a23bda0dbcfe11a35ae38475841b227f473fa8f71545689307c024493c012dceab4ab93ab33ee082dcdd23ecf3a39cceecd4bbd428c3028a7f36c265dc6'
      const config = {
        headers: { Authorization: `Bearer ${publicToken}` }
      };
      const data = await axios.get('https://api.oslocommunication.fr/api/combined?populate=deep', config)
      return data.data
    }
  },
  async mounted() {
    console.log('#kibwashere')

    this.data = await this.getData();
    const imageUrl = this.data?.accueil?.desktop_image?.url

    if (imageUrl && imageUrl.length) {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        this.isLoading = false
      };
    } else {
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
