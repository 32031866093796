<template>
  <div class="legal">
    <div class="legal-content">
      <div
        v-if="data && data['mentions-legale']?.image?.url"
        class="legal-cover"
        v-bind:style="{ 'background': 'url(' + data['mentions-legale'].image.url + ') no-repeat' }"
      >
        <h2>{{ data && data['mentions-legale']?.title }}</h2>
      </div>
      <div class="legal-blabla montserrat">
        <div class="l1">
          <div>
            <p style="white-space: break-spaces;">
              <span>{{ data && data['mentions-legale']?.editor_title }}</span>{{ data && data['mentions-legale']?.editor
              }}
            </p>
          </div>
          <div>
            <p style="white-space: break-spaces;">
              <span>{{ data && data['mentions-legale']?.host_title }}</span>{{ data && data['mentions-legale']?.host }}
            </p>
          </div>
        </div>
        <div class="l2">
          <div>
            <p style="white-space: break-spaces;">
              <span>{{ data && data['mentions-legale']?.data_title }}</span>{{ data && data['mentions-legale']?.data }}
            </p>
          </div>
          <div>
            <p style="white-space: break-spaces;">
              <span>{{ data && data['mentions-legale']?.laws_title }}</span>{{ data && data['mentions-legale']?.laws }}
            </p>
          </div>
          <div>
            <p style="white-space: break-spaces;">
              <span>{{ data && data['mentions-legale']?.copies_title }}</span>{{ data && data['mentions-legale']?.copies
              }}
            </p>
          </div>
        </div>
        <div class="back_home">
          <a
            v-if="data && data['mentions-legale']?.button?.href"
            :href="data['mentions-legale']?.button?.href"
          >{{ data && data['mentions-legale']?.button?.text }}</a>
        </div>
      </div>
    </div>
    <Contact :data="data?.contact" />
  </div>
</template>

<script>
import Contact from '@/views/7_Contact.vue'

export default {
  name: 'HomeView',
  components: {
    Contact
  },
  props: {
    data: Object
  },
}
</script>


<style scoped lang="scss">
.legal-content {
  padding-top: 80px;

  .legal-cover {
    min-height: 285px !important;
    background-size: cover;

    h2 {
      text-align: center;
      color: var(--color-light);
      padding-top: 150px;
      letter-spacing: 4px;
      font-size: 22px;
    }
  }

  .legal-blabla {
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    padding-bottom: 15px;
    padding-top: 15px;
    margin: auto;
    text-align: left;
    max-width: 1200px;

    .l1,
    .l2 {
      div {
        p {
          line-height: 24px;
          font-size: 13px;
          color: var(--color-gray);

          span {
            color: var(--color-dark);
            display: block;
            font-weight: bold;
          }
        }
      }
    }

    .back_home {
      margin: 70px 0;
      text-align: center;
    }

    .back_home a {
      display: inline-block;
      font-size: 11px;
      font-weight: normal;
      letter-spacing: 1px;
      text-transform: uppercase;
      border: 2px solid;
      padding: 10px 30px 10px 30px;
      max-width: 100%;
      font-family: montserratregular, sans-serif;
      font-weight: normal;
      border-radius: 25px;
      border: 1px solid;
      color: var(--color-dark);
      border-color: var(--color-dark);
      background-color: transparent;
      text-decoration: none;
    }

    .back_home a:hover {
      color: var(--color-light);
      background-color: var(--color-dark);
    }

    .l2 {}
  }
}

@media (min-width: $mq-m) {
  .legal-content {
    .legal-blabla {

      .l1 {
        div {
          width: 50%;
          vertical-align: top;
          display: inline-block;

        }
      }
    }
  }
}
</style>