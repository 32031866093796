<template>
  <div class="splashscreen">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Calque_1"
      x="0px"
      y="0px"
      width="250px"
      viewBox="0 0 90.2 78.6"
      style="enable-background:new 0 0 90.2 78.6;"
      xml:space="preserve"
    >
      <g>
        <g>
          <path
            class="st0"
            d="M105.4,47.2c-1.6,1.6-3.6,2.4-5.9,2.4c-2.4,0-4.3-0.8-5.9-2.4c-1.6-1.6-2.4-3.5-2.4-5.9 c0-2.3,0.8-4.3,2.4-5.9c1.6-1.6,3.6-2.4,5.9-2.4c2.4,0,4.3,0.8,5.9,2.4c1.6,1.6,2.4,3.5,2.4,5.9C107.8,43.7,107,45.6,105.4,47.2z M104.2,41.3c0-1.4-0.5-2.6-1.4-3.6c-0.9-1-2-1.5-3.4-1.5c-1.3,0-2.4,0.5-3.4,1.5c-0.9,1-1.4,2.2-1.4,3.6c0,1.4,0.5,2.6,1.4,3.6 c0.9,1,2,1.5,3.4,1.5c1.3,0,2.4-0.5,3.4-1.5C103.7,44,104.2,42.8,104.2,41.3z"
          ></path>
          <path
            class="st0"
            d="M118.2,36.7c-0.3,0.3-0.5,0.7-0.5,1.1c0,0.5,0.2,0.8,0.6,1.1c0.4,0.3,1.4,0.6,2.9,1c1.5,0.4,2.7,0.9,3.5,1.7 c0.8,0.7,1.3,1.8,1.3,3.3c0,1.4-0.5,2.6-1.6,3.5c-1.1,0.9-2.5,1.3-4.2,1.3c-2.5,0-4.8-0.9-6.8-2.8l2.1-2.6 c1.7,1.5,3.3,2.3,4.8,2.3c0.7,0,1.2-0.1,1.5-0.4c0.4-0.3,0.6-0.7,0.6-1.1c0-0.5-0.2-0.9-0.6-1.1c-0.4-0.3-1.2-0.6-2.3-0.9 c-1.9-0.4-3.2-1-4.1-1.7c-0.9-0.7-1.3-1.8-1.3-3.3c0-1.5,0.5-2.7,1.6-3.5c1.1-0.8,2.4-1.2,4.1-1.2c1.1,0,2.1,0.2,3.2,0.5 c1.1,0.4,2,0.9,2.8,1.5l-1.8,2.6c-1.4-1-2.8-1.6-4.3-1.6C119,36.2,118.6,36.4,118.2,36.7z"
          ></path>
          <path
            class="st0"
            d="M132.9,49.4V33.5h3.6v12.8h6.8v3.2H132.9z"
          ></path>
          <path
            class="st0"
            d="M162.7,47.2c-1.6,1.6-3.6,2.4-5.9,2.4c-2.4,0-4.3-0.8-5.9-2.4c-1.6-1.6-2.4-3.5-2.4-5.9 c0-2.3,0.8-4.3,2.4-5.9c1.6-1.6,3.6-2.4,5.9-2.4c2.4,0,4.3,0.8,5.9,2.4c1.6,1.6,2.4,3.5,2.4,5.9C165.1,43.7,164.3,45.6,162.7,47.2 z M161.5,41.3c0-1.4-0.5-2.6-1.4-3.6c-0.9-1-2-1.5-3.4-1.5s-2.4,0.5-3.4,1.5c-0.9,1-1.4,2.2-1.4,3.6c0,1.4,0.5,2.6,1.4,3.6 c0.9,1,2,1.5,3.4,1.5s2.4-0.5,3.4-1.5C161,44,161.5,42.8,161.5,41.3z"
          ></path>
          <path
            class="st0"
            d="M182.4,34c1,0.4,1.9,1,2.7,1.7l-1.1,1.4c-0.6-0.6-1.3-1.1-2.2-1.5c-0.8-0.4-1.7-0.5-2.5-0.5 c-1.2,0-2.3,0.3-3.3,0.8c-1,0.6-1.8,1.3-2.4,2.3c-0.6,1-0.9,2-0.9,3.2c0,1.1,0.3,2.2,0.9,3.2c0.6,1,1.4,1.7,2.4,2.3 c1,0.6,2.1,0.8,3.3,0.8c0.9,0,1.7-0.2,2.5-0.5c0.8-0.3,1.5-0.8,2.2-1.4l1.1,1.3c-0.8,0.8-1.7,1.4-2.8,1.8c-1,0.4-2.1,0.7-3.2,0.7 c-1.5,0-2.9-0.4-4.1-1.1c-1.3-0.7-2.3-1.7-3-2.9c-0.7-1.2-1.1-2.6-1.1-4.1c0-1.5,0.4-2.8,1.1-4c0.7-1.2,1.8-2.2,3-2.9 c1.3-0.7,2.7-1.1,4.2-1.1C180.3,33.4,181.4,33.6,182.4,34z"
          ></path>
          <path
            class="st0"
            d="M202.9,34.5c1.3,0.7,2.3,1.7,3,2.9c0.7,1.2,1.1,2.6,1.1,4c0,1.5-0.4,2.8-1.1,4.1c-0.7,1.2-1.8,2.2-3,2.9 c-1.3,0.7-2.7,1.1-4.2,1.1c-1.5,0-2.9-0.4-4.2-1.1c-1.3-0.7-2.3-1.7-3-2.9c-0.7-1.2-1.1-2.6-1.1-4.1s0.4-2.8,1.1-4.1 c0.7-1.2,1.8-2.2,3-2.9c1.3-0.7,2.7-1.1,4.2-1.1C200.2,33.4,201.6,33.8,202.9,34.5z M195.5,36c-1,0.6-1.8,1.3-2.4,2.3 c-0.6,1-0.9,2-0.9,3.2c0,1.2,0.3,2.2,0.9,3.2c0.6,1,1.4,1.7,2.4,2.3c1,0.6,2.1,0.8,3.2,0.8c1.2,0,2.2-0.3,3.2-0.8 c1-0.6,1.8-1.3,2.4-2.3c0.6-1,0.9-2,0.9-3.2c0-1.2-0.3-2.2-0.9-3.2c-0.6-1-1.4-1.7-2.4-2.3c-1-0.6-2.1-0.8-3.2-0.8 C197.5,35.1,196.4,35.4,195.5,36z"
          ></path>
          <path
            class="st0"
            d="M228.7,49.4l0-13.1l-5.8,11.5h-1.1L216,36.3v13.1h-1.7V33.5h2.3l5.8,11.5l5.8-11.5h2.2v15.9H228.7z"
          ></path>
          <path
            class="st0"
            d="M253.5,49.4l0-13.1l-5.8,11.5h-1.1l-5.8-11.5v13.1h-1.7V33.5h2.3l5.8,11.5l5.8-11.5h2.2v15.9H253.5z"
          ></path>
          <path
            class="st0"
            d="M275.2,47.7c-1.2,1.2-2.8,1.8-4.9,1.8c-2.1,0-3.7-0.6-4.9-1.8c-1.2-1.2-1.8-2.8-1.8-4.9v-9.4h1.8v9.4 c0,1.5,0.4,2.7,1.3,3.6c0.9,0.9,2.1,1.3,3.6,1.3c1.5,0,2.7-0.4,3.6-1.3c0.9-0.9,1.3-2.1,1.3-3.6v-9.4h1.8v9.4 C276.9,44.9,276.3,46.5,275.2,47.7z"
          ></path>
          <path
            class="st0"
            d="M296.6,49.4l-9.6-12.8v12.8h-1.8V33.5h1.9l9.6,12.8V33.5h1.8v15.9H296.6z"
          ></path>
          <path
            class="st0"
            d="M307.2,49.4V33.5h1.8v15.9H307.2z"
          ></path>
          <path
            class="st0"
            d="M327.7,34c1,0.4,1.9,1,2.7,1.7l-1.1,1.4c-0.6-0.6-1.3-1.1-2.2-1.5c-0.8-0.4-1.7-0.5-2.5-0.5 c-1.2,0-2.3,0.3-3.3,0.8c-1,0.6-1.8,1.3-2.4,2.3c-0.6,1-0.9,2-0.9,3.2c0,1.1,0.3,2.2,0.9,3.2c0.6,1,1.4,1.7,2.4,2.3 c1,0.6,2.1,0.8,3.3,0.8c0.9,0,1.7-0.2,2.5-0.5c0.8-0.3,1.5-0.8,2.2-1.4l1.1,1.3c-0.8,0.8-1.7,1.4-2.8,1.8c-1,0.4-2.1,0.7-3.2,0.7 c-1.5,0-2.9-0.4-4.1-1.1c-1.3-0.7-2.3-1.7-3-2.9c-0.7-1.2-1.1-2.6-1.1-4.1c0-1.5,0.4-2.8,1.1-4c0.7-1.2,1.8-2.2,3-2.9 c1.3-0.7,2.7-1.1,4.2-1.1C325.6,33.4,326.7,33.6,327.7,34z"
          ></path>
          <path
            class="st0"
            d="M338.9,45.4l-1.8,4h-1.9l7.1-15.9h1.9l7.1,15.9h-2l-1.8-4H338.9z M343.2,35.5l-3.5,8.1h7.1L343.2,35.5z"
          ></path>
          <path
            class="st0"
            d="M361.1,35.2v14.2h-1.8V35.2h-5.2v-1.8h12.2v1.8H361.1z"
          ></path>
          <path
            class="st0"
            d="M372.8,49.4V33.5h1.8v15.9H372.8z"
          ></path>
          <path
            class="st0"
            d="M394.4,34.5c1.3,0.7,2.3,1.7,3,2.9c0.7,1.2,1.1,2.6,1.1,4c0,1.5-0.4,2.8-1.1,4.1c-0.7,1.2-1.8,2.2-3,2.9 c-1.3,0.7-2.7,1.1-4.2,1.1c-1.5,0-2.9-0.4-4.2-1.1c-1.3-0.7-2.3-1.7-3-2.9c-0.7-1.2-1.1-2.6-1.1-4.1s0.4-2.8,1.1-4.1 c0.7-1.2,1.8-2.2,3-2.9c1.3-0.7,2.7-1.1,4.2-1.1C391.7,33.4,393.1,33.8,394.4,34.5z M386.9,36c-1,0.6-1.8,1.3-2.4,2.3 c-0.6,1-0.9,2-0.9,3.2c0,1.2,0.3,2.2,0.9,3.2c0.6,1,1.4,1.7,2.4,2.3c1,0.6,2.1,0.8,3.2,0.8c1.2,0,2.2-0.3,3.2-0.8 c1-0.6,1.8-1.3,2.4-2.3c0.6-1,0.9-2,0.9-3.2c0-1.2-0.3-2.2-0.9-3.2c-0.6-1-1.4-1.7-2.4-2.3c-1-0.6-2.1-0.8-3.2-0.8 C389,35.1,387.9,35.4,386.9,36z"
          ></path>
          <path
            class="st0"
            d="M417.1,49.4l-9.6-12.8v12.8h-1.8V33.5h1.9l9.6,12.8V33.5h1.8v15.9H417.1z"
          ></path>
        </g>
        <path
          class="st1"
          d="M71.8,34.5C75.1,21.8,60.5,19.2,52,15c-2.3,5.2,14.9,8.4,13.9,14.5c-0.9,5.1-9.2,2-13.4,1 c-1.9,6.7,8.4,6.3,9.4,10.5c0.9,3.7-4.6,7.7-7.4,8.5c-9.8,2.9-23.6,0.6-24.6-7.5c-0.1-5.3,9.8-4.5,8.7-11.5 c-4.7,0.8-13,4.4-13.7-1.5c0.1-2.1,4.3-4.9,7.3-7c2.6-1.9,7.4-4,5.9-7c-7.4,3.4-15.6,7.1-19.1,12.7c0,0-1.9,4.2-0.2,6.8 c0,0-3,2.1-2,5.5c2.1,7.4,12.1,10.4,17.3,14.5c3.9,3.1,6.5,12.9,12.4,12c4.3-0.7,7.9-10.1,11.4-12.5C65,49.2,78.9,44.4,71.8,34.5 M19.4,36.8c1.7,0.2,1.6,2.4,2,4C19.4,40.8,19.2,38.9,19.4,36.8 M43.4,64c1.2-0.4,2.6-1,4.6,0.1C48,66.6,43.4,66.6,43.4,64 M69.4,41.1c0.4-1.6,0.2-3.8,2-4C71.5,39.2,71.4,41.1,69.4,41.1"
        ></path>
      </g>
    </svg>
  </div>
</template>

<style scoped lang="scss">
.splashscreen {
  padding-top: 10%;
}

svg {
  animation: blink 1s infinite alternate;
}

@keyframes blink {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

.st0 {
  fill: #165887;
}

.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #165887;
}
</style>