<template>
  <div
    id="the_studio"
    class="the-studio"
  >
    <div>
      <div class="left">
        <h1>{{ data?.title }}</h1>
        <p style="white-space: break-spaces;">{{ data?.description }}</p>
        <a :href="data?.button?.href">{{ data?.button?.text }}</a>
      </div>
      <div class="right">
        <img
          :src="data?.map?.url"
          alt="map"
          loading="lazy"
        />
      </div>
    </div>
    <div class="studio-metrics">
      <div
        v-if="data?.Metrique"
        v-for="metric in data.Metrique"
      >
        <div class="studio-metric">
          <div :data-test="`number${metric.id}`">
            <number
              :ref="'number' + metric.id"
              animationPaused
              :from="metric.from"
              :to="metric.to"
              :duration="metric.duration"
              easing="Power1.easeOut"
            />
          </div>
          <div>
            {{ metric.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TheStudio',
  props: {
    data: Object
  },
  data() {
    return {
      offsetTop: 0,
      m1Triggered: false,
      m2Triggered: false,
      m3Triggered: false,
      m4Triggered: false,
      m5Triggered: false,
    }
  },
  created() {
    window.addEventListener('scroll', this.onScroll);
  },
  watch: {
    offsetTop(val) {
      this.listenScrolling()
    }
  },
  methods: {
    onScroll(e) {
      if (!this.m5Triggered) {
        this.offsetTop = window.pageYOffset || document.documentElement.scrollTop
      } else {
        window.removeEventListener('scroll', this.onScroll);
      }
    },
    isElementInViewport(el) {
      var rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    listenScrolling() {
      let items = document.querySelectorAll('.studio-metric');

      for (var i = 0; i < items.length; i++) {
        if (this.isElementInViewport(items[i])) {
          this.$refs[`number${i + 1}`][0].play()
          this[`m${i + 1}Triggered`] = true
        }
      }
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll);
  },
}
</script>

<style scoped lang="scss">
.the-studio {
  >div:first-child {
    padding: 30px 10px;

    p {
      font-size: 14px;
      line-height: 24px;
      color: var(--color-blue);
    }

    .left {
      text-align: left;

      a {
        text-decoration: none;
        color: var(--color-dark);
        border-radius: 25px !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
        border: 2px solid var(--color-dark);

        font-family: 'Montserrat', sans-serif;
        display: inline-block;
        font-size: 11px;
        font-weight: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
        border: 2px solid;
        padding: 12px 37px 10px 40px;
        max-width: 100%;

        display: inline-block;
        margin: 40px 0px;
      }
    }

    .right {
      img {
        width: 100%;
      }
    }
  }

  .studio-metrics {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: table-cell;
    vertical-align: middle;

    width: 100%;
    height: 400px;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: table;
    table-layout: fixed;
    overflow: hidden;
    background-color: #fff;

    >div {
      height: auto;
      min-height: 0%;
      margin-bottom: -1px;
      display: block;
      width: 100%;
      box-sizing: border-box;
      margin-right: 0;
      margin-left: 0;
      border-top: 1px solid #d3d3d3;
      border-left: 1px solid #d3d3d3;
      border-bottom: 1px solid #d3d3d3;

      float: left;
      padding-left: 15px;
      padding-right: 15px;
      position: relative;
      padding-bottom: 15px;
      padding-top: 15px;
    }

    .studio-metric {
      width: 100%;
      margin: 15px auto;
      padding: 27px 0px;
      text-align: center;
      box-sizing: border-box;

      div:first-child {
        display: block;
        font-size: 22px;
        font-weight: normal;
        line-height: 1 !important;
        margin-bottom: 9px !important;
        font-family: 'Montserrat', sans-serif;
      }

      div:last-child {
        font-size: 0.75em;
        font-weight: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
      }
    }
  }
}

@media (min-width: $mq-m) {
  .the-studio {
    >div:first-child {
      margin: auto;
      max-width: 1200px;
      display: flex;
      align-items: center;
      justify-content: center;

      .left {
        width: 50%;
        display: inline-block;
      }

      .right {
        width: 50%;
        display: inline-block;
      }
    }

    .studio-metrics {
      height: auto;

      >div {
        width: 20%;
        display: inline-block;
      }

      .studio-metric {

        div:first-child {
          font-size: 50px;
        }

        div:last-child {
          letter-spacing: 1px;
          font-size: 0.75em;
        }
      }
    }
  }
}
</style>
