<template>
  <div
    id="contact"
    class="contact"
  >
    <div>
      <div class="left">
        <div class="logo">
          <img
            :src="data?.logo?.url"
            alt=""
          />
        </div>
        <div class="contact-item">
          <img
            :src="data?.address_icon?.url"
            alt=""
            loading="lazy"
          />
          <p>{{ data?.address }}</p>
        </div>
        <div class="contact-item">
          <img
            :src="data?.phone_icon?.url"
            alt=""
            loading="lazy"
          />
          
          <p>
            <a :href="'tel:' + data?.phone">
              {{ data?.phone }}
            </a>
          </p>
        </div>
        <div class="contact-item">
          <img
            :src="data?.email_icon?.url"
            alt=""
            loading="lazy"
          />
          <p>
            <a :href="'mailto:' + data?.email">
              {{ data?.email }}
            </a>
          </p>
        </div>
      </div>
      <div class="right">
        <div>
          <h1>{{ data?.title }}</h1>
        </div>
        <div>
          <form
            v-if="!hasEmailBeenSent"
            v-on:submit.prevent="sendEmail"
          >
            <input
              type="text"
              required
              v-model="emailName"
              :placeholder="data?.form_name"
            />
            <input
              type="email"
              required
              v-model="emailEmail"
              :placeholder="data?.form_email"
            />
            <input
              type="text"
              required
              v-model="emailSujet"
              :placeholder="data?.form_sujet"
            />
            <textarea
              required
              v-model="emailMessage"
              :placeholder="data?.form_message"
            ></textarea>
            <div class="button-send">
              <input
                type="submit"
                value="ENVOYER"
              />
            </div>
          </form>
          <div v-else>
            <p>
              Votre message a bien été envoyé.
            </p>
          </div>
        </div>
      </div>

    </div>
    <div id="map"></div>
    <div id="g_map">
      <!-- <iframe
        title="gmap"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2625.7359621979244!2d2.284596451523522!3d48.844174879184465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6719a7d8d8549%3A0x6934a6102bfc8db4!2sOslo%20Communication!5e0!3m2!1sfr!2sfr!4v1679762578103!5m2!1sfr!2sfr"
        width="600"
        height="450"
        style="border:0;"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe> -->
    </div>
    <footer>
      <div>
        <span>{{ data?.footer }} <a
            href="/legal"
            target="_blank"
          >Mentions légales</a></span>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from 'axios';
import { Loader } from '@googlemaps/js-api-loader';

export default {
  name: 'Contact',
  props: {
    data: Object
  },
  data() {
    return {
      hasEmailBeenSent: false,
      emailName: null,
      emailEmail: null,
      emailSujet: null,
      emailMessage: null,
    }
  },
  async created() {
    const loader = new Loader({
      apiKey: "AIzaSyA7JieMYhZcUNr5XHHIKhEi1sTCRYDnIDY",
      version: "weekly",
      libraries: ["places"]
    });

    loader
      .load()
      .then((google) => {
        this.initMap(google);
      })
      .catch(e => {
        // do something, or not
      });
  },
  methods: {
    sendEmail: async function () {
      const name = this.emailName;
      const email = this.emailEmail;
      const subject = this.emailSujet;
      const message = this.emailMessage;

      const publicToken = '881f9075af275b89f8404b19b96505839c038334561c0beb1a23a52f331298a268bd55dfb4a9a5781d848006f00359262d808d35e5200355782ba1aff956c390ccbf3a23bda0dbcfe11a35ae38475841b227f473fa8f71545689307c024493c012dceab4ab93ab33ee082dcdd23ecf3a39cceecd4bbd428c3028a7f36c265dc6'
      const config = {
        headers: { Authorization: `Bearer ${publicToken}` },
        params: { name, email, subject, message }
      };
      const data = await axios.post('https://api.oslocommunication.fr/api/emails', null, config)
      const hasBeenEmitted = data?.data

      if (!hasBeenEmitted) {
        return
      }

      this.hasEmailBeenSent = true
    },
    initMap: function (google) {
      const gSettings = {
        map_id: 'map',
        title: 'Oslo Communication !',
        location: 'Oslo Communication Paris',
        location_ll: { lat: 48.8441749, lng: 2.2842102 },
        zoom: 13,
        hue: '#165887',
        saturation: -70,
        lightness: 0,
        iconLink: 'https://kznjunk.s3.eu-west-3.amazonaws.com/oslo_communication/logo_place_e76cdcd002.png'
      }

      var styles = [
        {
          stylers: [
            { hue: gSettings.hue },
            { saturation: gSettings.saturation },
            { lightness: gSettings.lightness }
          ]
        }
      ];

      var mapOptions = {
        zoom: gSettings.zoom,
        scrollwheel: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: styles,
        center: gSettings.location_ll
      };

      const map = new google.maps.Map(document.getElementById("map"), mapOptions);
      const marker = new google.maps.Marker({
        position: gSettings.location_ll,
        map: map,
        icon: gSettings.iconLink,
      });

      // geocoder = new google.maps.Geocoder();
      // geocoder.geocode( { 'address': gSettings.location}, function(results, status) {
      // 	if (status == google.maps.GeocoderStatus.OK) {
      // 		map = new google.maps.Map(document.getElementById("map"), mapOptions);
      // 		map.setCenter(results[0].geometry.location);
      // 		var marker = new google.maps.Marker({
      // 		  map: map, 
      // 		  position: results[0].geometry.location,
      // 		  title : gSettings.location,
      // 		  icon: gSettings.iconLink

      // 		});
      // 		var contentString = '<div class="map-infowindow">'+
      // 			( (gSettings.title) ? '<h3>' + gSettings.title + '</h3>' : '' ) + 
      // 			gSettings.location + '<br/>' +
      // 			'<a href="https://maps.google.com/?q='+ gSettings.location +'" target="_blank">View on Google Map</a>' +
      // 			'</div>';
      // 		var infowindow = new google.maps.InfoWindow({
      // 		  content: contentString
      // 		});
      // 		google.maps.event.addListener(marker, 'click', function() {
      // 			infowindow.open(map,marker);
      // 		});
      // 	} else {
      // 		console.log(status)
      // 	}
      // });
    }
  }
}
</script>

<style scoped lang="scss">
.contact {
  .left {
    >div:first-child {
      text-align: center;
      padding: 30px 10px;
      margin: 10px 0px;
    }

    .logo img {
      width: 120px;
      margin: auto;
    }

    .contact-item {
      margin: auto;
      display: block;
      font-size: 13px;
      color: var(--color-blue);

      img {
        width: 45px;
        margin-top: 20px;
      }
      a {
        color: inherit;
    text-decoration: none;
      }
    }
  }

  .right {
    >div:first-child {
      text-align: left;
      padding: 30px 10px;

      h1 {
        margin-top: 0px;
        margin-bottom: 20px;
      }

      p {
        font-size: 13px;
      }
    }

    >div:nth-child(2) {
      box-sizing: border-box;
      padding: 15px;
      margin-bottom: 50px;
    }

    form {
      box-sizing: border-box;

      input {
        box-sizing: border-box;
        display: block;
        width: 100%;
        width: 100%;
        height: 40px;
        padding: 0px 10px;
        border: 1px solid var(--color-blue);
        color: var(--color-blue);
        opacity: 1;
        font-size: 13px;
        font-style: normal;
        background: transparent;
        margin-top: 15px;
      }

      textarea {
        box-sizing: border-box;
        margin-top: 15px;
        width: 100%;
        max-width: 100%;
        min-height: 150px;
        padding: 15px 10px;
        border: 1px solid var(--color-blue);
        color: var(--color-blue);
        font-style: normal;
        font-size: 13px;
        border-radius: 0px;
        background: transparent;
        resize: none;
      }

      input:focus,
      textarea:focus {
        outline: none !important;
        border: 1px solid var(--color-blue);
      }

      ::placeholder {
        color: var(--color-blue);
      }

      .button-send {
        text-align: left;
        vertical-align: bottom;
        margin-bottom: 10px;
        position: relative;
        bottom: 15px;
        margin-top: 50px;
      }

      input[type="submit"] {
        display: inline-block;
        font-size: 11px;
        font-weight: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
        border: 2px solid;
        padding: 10px 30px 10px 30px;
        max-width: 100%;
        font-family: montserratregular, sans-serif;
        font-weight: normal;
        border-radius: 25px;
        border: 1px solid var(--color-blue);
        color: var(--color-dark);
        background-color: transparent;
        text-decoration: none;
        width: auto;
        color: var(--color-blue);
      }

      input[type="submit"]:hover {
        color: var(--color-blue);
        background-color: rgb(204, 233, 223);
      }
    }
  }

  #map {
    width: 100%;
    height: 450px;
  }

  #g_map {
    width: 100%;

    iframe {
      width: 100%;
    }
  }

  footer {
    width: 100%;

    div {
      padding-left: 15px;
      padding-right: 15px;
      position: relative;
      padding-bottom: 15px;
      padding-top: 15px;

      span {
        color: var(--color-blue);
        text-transform: uppercase;
        font-size: 11px;
      }

      a {
        text-decoration: none;
        color: var(--color-blue);
      }
    }
  }
}

@media (min-width: $mq-m) {
  .contact {
    >div:first-child {
      margin: auto;
      text-align: center;
      max-width: 1200px;
      vertical-align: top;
    }

    .left {
      width: 50%;
      vertical-align: top;
      display: inline-block;
    }

    .right {
      width: 50%;
      vertical-align: top;
      display: inline-block;

      >div:first-child {
        padding-left: 50px;
      }
      >div:nth-child(2) {
        padding-left: 50px;
      }
    }
  }
}
</style>
