<template>
    <div
        class="carousel"
        ref="carousel"
        :style="cssProps"
    >
        <slot />
        <div class="pagination">
            <div
                v-for="n in numberOfBullets"
                @click="changeSlide(n)"
                v-bind:class="{ active: n === activeSlide }"
                v-if="pagination && numberOfBullets > 1"
            >
            </div>
        </div>
    </div>
</template>

<script>
import { debounce } from '@/helpers'

export default {
    props: {
        autoPlay: Boolean,
        autoPlayTime: {
            type: Number,
            default: 1000
        },
        draggable: Boolean,
        pagination: Boolean,
        numberOfItems: {
            type: Number,
            default: 1
        },
        numberOfItemsToShow: Number,
        numberOfItemsToShowCustom: Array
    },
    data: function () {
        return {
            nbToShow: 0,
            leftMargin: 0,
            activeSlide: 1,
            onResizeRate: 50,
            autoPlayTimeout: null,
            slideWidth: `${100 / this.numberOfItemsToShow}%`,
            numberOfBullets: this.numberOfItems - (this.numberOfItemsToShow - 1) || 1,

            savedleftMargin: 0,
            dragging: false,
            dragMomentum: 0,
            dragOffset: 0,
            dragStartY: 0,
            dragStartX: 0,
            isTouch: typeof window !== "undefined" && "ontouchstart" in window,
            maxOffset: (100 / this.nbToShow) * (this.numberOfBullets - 1),
            resistanceCoef: 0.5
        }
    },
    created() {
        const isAutoPlayEnable = this.autoPlay && this.numberOfBullets > 1
        if (isAutoPlayEnable) {
            this.playCarousel()
        }

        const isCustomWidthValues = this.numberOfItemsToShowCustom && this.numberOfItemsToShowCustom.length
        this.onResize()
        if (isCustomWidthValues) {
            window.addEventListener('resize', debounce(this.onResize, this.onResizeRate))
        }
    },
    mounted() {
        if (this.draggable) {
            this.$refs['carousel'].addEventListener(
                this.isTouch ? 'touchstart' : 'mousedown',
                this.onClickCarousel,
                {passive: true}
            )
        }
    },
    unmounted() {
        clearTimeout(this.autoPlayTimeout)
        window.removeEventListener('resize', this.onResize)

        document.removeEventListener(
            this.isTouch ? 'touchend' : 'mouseup',
            this.onUnclickedCarousel,
            true
        );
        document.removeEventListener(
            this.isTouch ? 'touchmove' : 'mousemove',
            this.onDrag,
            true
        )
    },
    methods: {
        changeSlide(indexBullet) {
            const newMargin = (100 / (this.nbToShow || this.numberOfItemsToShow)) * (indexBullet - 1)

            this.leftMargin = newMargin
            this.savedleftMargin = newMargin
            this.activeSlide = indexBullet
        },
        playCarousel() {
            const nextSlide = this.activeSlide++ % this.numberOfBullets

            this.changeSlide(nextSlide + 1)
            this.autoPlayTimeout = setTimeout(this.playCarousel, this.autoPlayTime)
        },
        pauseCarousel() {
            clearTimeout(this.autoPlayTimeout)
        },
        onResize() {
            const currentWidth = window.innerWidth

            for (let i = this.numberOfItemsToShowCustom.length; i > 0; i--) {
                const config = this.numberOfItemsToShowCustom[i - 1]
                const minThreshold = config[0]

                if (currentWidth > minThreshold) {
                    if (this.nbToShow !== config[1]) {
                        this.nbToShow = config[1]
                        this.leftMargin = 0
                        this.savedleftMargin = 0
                        this.slideWidth = `${100 / this.nbToShow}%`
                        this.numberOfBullets = this.numberOfItems - (this.nbToShow - 1) || 1
                        this.maxOffset = (100 / this.nbToShow) * (this.numberOfBullets - 1)

                        if (this.autoPlay && this.numberOfBullets > 1) {
                            clearTimeout(this.autoPlayTimeout)
                            this.playCarousel()
                        }
                    }
                    break;
                }
            }
        },
        onClickCarousel(e) {
            const isRightClick = e.button === 2
            if (isRightClick) return

            this.pauseCarousel()

            document.addEventListener(
                this.isTouch ? 'touchend' : 'mouseup',
                this.onUnclickedCarousel,
                true
            )
            document.addEventListener(
                this.isTouch ? 'touchmove' : 'mousemove',
                this.onDrag,
                true
            )

            this.startTime = e.timeStamp
            this.dragging = true
            this.dragStartX = this.isTouch ? e.touches[0].clientX : e.clientX
            this.dragStartY = this.isTouch ? e.touches[0].clientY : e.clientY
        },
        onUnclickedCarousel(e) {
            this.savedleftMargin = this.leftMargin

            const step = (100 / (this.nbToShow || this.numberOfItemsToShow))
            let nearestSlide = Math.round(this.leftMargin / step) + 1
            if (nearestSlide >= this.numberOfBullets) {
                this.changeSlide(this.numberOfBullets)
            } else if (nearestSlide < 1) {
                this.changeSlide(1)
            } else {
                this.changeSlide(nearestSlide)
            }

            const isAutoPlayEnable = this.autoPlay && this.numberOfBullets > 1
            if (isAutoPlayEnable) {
                this.playCarousel()
            }

            document.removeEventListener(
                this.isTouch ? 'touchend' : 'mouseup',
                this.onUnclickedCarousel,
                true
            )
            document.removeEventListener(
                this.isTouch ? 'touchmove' : 'mousemove',
                this.onDrag,
                true
            )
        },
        onDrag(e) {
            const eventPosX = this.isTouch ? e.touches[0].clientX : e.clientX
            const eventPosY = this.isTouch ? e.touches[0].clientY : e.clientY
            const newOffsetX = this.dragStartX - eventPosX
            const newOffsetY = this.dragStartY - eventPosY

            const isBelowMinSwipe = this.isTouch && Math.abs(newOffsetX) < Math.abs(newOffsetY)
            if (isBelowMinSwipe) return

            e.stopImmediatePropagation()

            this.dragOffset = newOffsetX
            const goToRight = this.dragOffset > 0
            const goToLeft = this.dragOffset < 0

            const newLeftValid = this.savedleftMargin + (newOffsetX / 1.5) // + (newOffsetX / 3)
            const isLeftValid = goToLeft && newLeftValid > 0
            const isRightValid = goToRight && newLeftValid < this.maxOffset

            if (isLeftValid || isRightValid) {
                this.leftMargin = newLeftValid
            }
        }
    },
    computed: {
        cssProps() {
            return {
                '--carousel-slide-current-left-margin': `-${this.leftMargin}%`,
                '--carousel-slide-width': this.slideWidth
            }
        }
    }
}
</script>

<style lang="scss">
.carousel {
    cursor: pointer;
    overflow-x: hidden;
    white-space: nowrap;

    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .slide:nth-child(1) {
        margin-left: var(--carousel-slide-current-left-margin);
    }

    .slide {
        width: var(--carousel-slide-width);
    }

    .pagination {
        margin-top: 30px;

        div {
            cursor: pointer;
            margin: 0px 10px;
            position: relative;
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 25px;
            border: 1px solid var(--color-black);
            background-color: var(--color-gray);
        }

        div.active {
            // background-color: var(--color-black);
            background-color: var(--color-light);
        }
    }
}
</style>
