<template>
    <div
        class="testimonials"
        v-if="data?.length"
    >
        <div>
            <Carousel
                :autoPlay="false"
                :pagination="true"
                :draggable="true"
                :autoPlayTime="5000"
                :numberOfItems="data.length"
                :numberOfItemsToShow="1"
                :numberOfItemsToShowCustom="[[1, 1], [559, 1], [879, 1]]"
            >
                <Slide
                    v-for="item in data"
                    v-bind:key="item?.id"
                >
                    <h3
                        v-html="item.text"
                        class="montserrat"
                    ></h3>
                    <p>{{ item.author }}</p>
                    <p>{{ item.company }}</p>
                </Slide>
            </Carousel>
        </div>
    </div>
</template>

<script>
import Slide from '@/components/Noice/Slide.vue'
import Carousel from '@/components/Noice/Carousel.vue'

export default {
    components: {
        Slide,
        Carousel
    },
    props: {
        data: Object
    }
}
</script>

<style lang="scss" scoped>
.testimonials {
    .carousel {
        padding: 20px 10px;
        margin: 20px;
    }

    h3 {
        white-space: pre-line;
        color: var(--color-light);
        margin: auto auto 1.5em;
        background: transparent;
        border: none;
        padding: 20px 0 0 0;
        font-size: 1em;
        font-style: italic;
        line-height: 1.5em;
    }

    h3:before {
        content: '‘‘';
        font-family: serif;
        font-size: 50px;
        letter-spacing: -5px;
        vertical-align: text-bottom;
        padding-right: 5px;
    }

    h3:after {
        content: '’’';
        font-family: serif;
        display: inline-block;
        font-size: 50px;
        letter-spacing: -5px;
        vertical-align: -120%;
        padding-left: 5px;
    }

    p {
        color: var(--color-light);
        font-size: 14px;
        font-style: normal;
    }
}
</style>