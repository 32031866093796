<template>
  <nav>
    <div>
      <a href="/">
        <img
          :src="data?.logo?.url"
          alt="Oslo Communication"
        />
      </a>
      <div>
        <a
          href="https://www.twitter.com/@oslocomparis"
          target="_blank"
        >
          <font-awesome-icon icon="fa-brands fa-twitter" />
        </a>
        <a
          class="facebook-icon"
          href="https://www.facebook.com/oslocommunication"
          target="_blank"
        >
          <font-awesome-icon icon="fa-brands fa-facebook-f" />
        </a>
        <a
          href="https://www.instagram.com/oslo_communication/"
          target="_blank"
        >
          <font-awesome-icon icon="fa-brands fa-instagram" />
        </a>

        <font-awesome-icon
          v-if="!isMenuOpen"
          v-on:click="isMenuOpen = true"
          class="hamburger"
          icon="fa-solid fa-bars"
          size="xl"
        />
        <font-awesome-icon
          v-if="isMenuOpen"
          v-on:click="isMenuOpen = !isMenuOpen"
          class="close"
          icon="fa-solid fa-arrow-right"
          size="xl"
        />
      </div>
    </div>
  </nav>
  <Transition>
    <div
      class="overflow"
      v-if="isMenuOpen"
      v-on:click="isMenuOpen = false"
    >
      <div class="right-menu">
        <ul>
          <li></li>
          <li v-for="item in data.index">
            <a @click="scrollToSection(item.href)">{{ item.text }}</a>
          </li>
        </ul>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'Nav',
  props: {
    data: Object
  },
  data() {
    return {
      isMenuOpen: false
    }
  },
  methods: {
    scrollToSection(id) {
      const element = document.getElementById(id)

      const y = element.getBoundingClientRect().top + window.pageYOffset - 80;

      if (element) window.scrollTo({top: y, behavior: 'smooth'})
      // element.scrollIntoView({ top: y, behavior: 'smooth' })
    }
  }
}
</script>

<style scoped lang="scss">
nav {
  z-index: 2;
  width: 100%;
  height: 90px;
  position: fixed;
  padding: 20px 20px;
  box-sizing: border-box;
  background-color: var(--bg-color-light);

  >div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;

    a>img {
      height: 50px;
    }

    a:focus {
      outline: none;
    }

    >div {
      top: 15px;
      right: 0px;
      position: absolute;

      .facebook-icon {
        border-left: 1px solid var(--color-dark);
        border-right: 1px solid var(--color-dark);
      }

      a {
        height: 20px;
        display: none;
        padding: 0px 20px;
        color: var(--color-dark);
      }

      i {
        color: var(--color-dark);
      }

      .hamburger {
        cursor: pointer;
        margin-left: 10px;
      }

      .close {
        margin-left: 10px;
      }
    }
  }
}

@media (min-width: $mq-s) {
  nav>div {
    justify-content: center;

    >div {
      right: 50px;
    }
  }
}

@media (min-width: $mq-m) {
  nav>div>div a {
    display: inline-block;
  }
}

.overflow {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: transparent;

  .right-menu {
    z-index: 2;
    top: 90px;
    right: 0px;
    height: 100%;
    width: 250px;
    padding: 26px 0;
    position: fixed;
    overflow: auto;
    background-color: var(--bg-color-dark);

    ul {
      counter-reset: link-counter;
      margin: 0;
      padding: 0;
      width: 100%;
      list-style: none;
      list-style-image: none;
      padding-bottom: 120px;

      li {
        position: relative;
        width: 100%;
        margin: 0;
        border-bottom: 1px solid #222222;
        display: block;
        line-height: 60px;
        height: auto;
        margin-bottom: 16px;

        a {
          font-size: 12px;
          height: 100%;
          display: block;
          padding-top: 37px;
          word-wrap: break-word;
          color: var(--color-light);
          text-decoration: none;
          text-transform: uppercase;
          position: relative;
          letter-spacing: 1px;
        }

        a:active {
          color: var(--color-blue);
        }

        a:before {
          content: counter(link-counter, upper-roman);
          counter-increment: link-counter;
          color: inherit;
          text-align: center;
          display: block;
          position: absolute;
          top: 0px;
          left: 50%;
          margin-left: -35px;
          border-bottom: 1px solid #222222;
          height: 37px;
          width: 70px;
          line-height: 37px;
        }
      }

      li:hover a {
        color: var(--color-blue);
      }
    }
  }
}


.v-enter-active,
.v-leave-active {
  transition: 0.4s ease;
}

.v-enter-from {
  transform: translate(100%, 0);
}

.v-leave-to {
  transform: translate(100%, 0);
}
</style>
