<template>
  <div
    id="references"
    class="references"
  >
    <div>
      <h1>{{ data?.title }}</h1>
      <p style="white-space: break-spaces;">{{ data?.description }}</p>
    </div>
    
    <div class="slider-logos">
      <SliderLogos :data="data?.logos">
      </SliderLogos>
    </div>

    <div
      class="slider-images"
      v-if="data?.background_image_quotes?.url"
      v-bind:style="{ 'background-image': 'url(' + data.background_image_quotes.url + ')' }"
    >
      <SliderQuotes :data="data?.Citations">
      </SliderQuotes>
    </div>
  </div>
</template>

<script>
import SliderLogos from '@/components/Images/SliderLogos.vue'
import SliderQuotes from '@/components/Texts/SliderQuotes.vue'

export default {
  name: 'References',
  components: {
    SliderLogos,
    SliderQuotes
  },
  props: {
    data: Object
  },
  data() {
    return {
      'bwop': 'bwop',
    }
  },
}
</script>

<style scoped lang="scss">
.references {
  background-color: var(--bg-color-white);

  >div:first-child {
    text-align: left;
    padding: 30px 10px;
    margin: 50px 0px;

    p {
      font-size: 13px;
    }
  }

  .slider-images {
    width: 100%;
    padding: 80px 0px;
    overflow: hidden;
    background-attachment: fixed;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: rgb(246, 246, 246);
    background-position: 50% 75.9111px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  @supports ( -webkit-touch-callout : none) {
    .slider-images {
      background-attachment: scroll;
      background-position: 80% 100%;
    }
  }
  @supports not ( -webkit-touch-callout : none) {
    .slider-images {
      background-attachment: fixed;
    }
  }
}

@media (min-width: $mq-m) {
  .references {
    >div:first-child {
      margin: auto;
      text-align: left;
      max-width: 1200px;
    }

    .slider-logos {
      margin: auto;
      text-align: center;
      max-width: 1200px;
    }

    .slider-images {}
  }
}
</style>
