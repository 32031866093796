<template>
  <div
    id="prestations"
    class="prestations"
    v-if="data?.image?.url"
    v-bind:style="{ 'background-image': 'url(' + data.image.url + ')' }"
  >
    <div class="half-width-page right">
      <div>
        <h1>{{ data?.title }}</h1>
        <p style="white-space: break-spaces;">{{ data?.description }}</p>
      </div>
      <div
        v-if="data?.prestation"
        v-for="item in data.prestation"
        class="presta"
      >
        <img
          :src="item.icon?.url"
          alt=""
          loading="lazy"
        />
        <h6>{{ item.title }}</h6>
        <p>{{ item.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Prestations',
  props: {
    data: Object
  },
}
</script>

<style scoped lang="scss">
.prestations {
  overflow: hidden;
  text-align: left;
  // background-size: cover;
  // background-position: top;
  // background-attachment: scroll !important;

  background-attachment: fixed;
  // padding-top: 100px;
  // padding-bottom: 100px;
  background-color: rgb(246, 246, 246);
  background-position: 100% 80px;
  background-size: cover;

  .half-width-page.right {
    opacity: 0.8;
    top: 0px;
    padding: 30px 25px;
    background-color: rgba(255, 255, 255, 0.8);
  }

  .half-width-page {
    float: right;
    position: relative;

    p {
      font-size: 13px;
      line-height: 24px;
      color: var(--color-dark);
    }

    img {
      top: 0px;
      width: 90px;
      position: absolute;
      margin-left: -30px;
    }

    h6 {
      margin-top: 90px;
      line-height: 12px;
      font-size: 12px;
      line-height: 12px;
      color: var(--color-blue);
      text-transform: uppercase;
    }

    .presta {
      position: relative;
      padding-right: 30px;
      display: inline-block;
    }

    .presta p {
      color: var(--color-blue);
    }

    .presta:nth-child(4) img {
      margin-left: -25px;
    }

    .presta:nth-child(5) img {
      margin-left: -25px;
    }
  }
}

@supports ( -webkit-touch-callout : none) {
  .prestations {
    background-attachment: scroll;
    background-position: 100%;
  }
}
@supports not ( -webkit-touch-callout : none) {
  .prestations {
    background-attachment: fixed;
  }
}

@media (min-width: $mq-m) {
  .prestations {

    .half-width-page.right {
      width: 45%;
      right: 0px;
      float: right;
      padding-left: 60px;
      padding-right: 60px;
    }

    .half-width-page {
      width: 100%;

      .presta {
        width: 50%;
        padding-right: 0px;
        vertical-align: top;
        display: inline-block;

        h6,
        p {

          padding-right: 50px;
        }
      }
    }
  }
}
</style>
