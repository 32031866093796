<template>
  <div
    id="accueil"
    class="fullsize-image"
  >
    <img
      class="main-image"
      :src="data?.desktop_image?.url"
      alt=""
    />
    <div>{{ data?.slogan }}</div>
    <img
      v-if="data?.decouvrir?.url"
      class="discover-image"
      :src="data?.decouvrir?.url"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: 'FullsizeImage',
  props: {
    data: Object
  },
}
</script>

<style scoped lang="scss">
.fullsize-image {
  position: relative;

  .main-image {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .discover-image {
    position: absolute;
    margin: auto;
    bottom: 0;
    left: 0;
    right: 0;
  }

  div {
    top: 45%;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 30px;
    color: var(--color-light);
    text-align: center;
    position: absolute;
    font-weight: 500;
    font-family: 'GrouchBT', sans-serif;
  }
}

@media (min-width: $mq-s) {
  .fullsize-image div {
    font-size: 60px;
  }
}

@media (min-width: $mq-m) {
  .fullsize-image div {
    font-size: 80px;
  }
}

@media (min-width: $mq-l) {
  .fullsize-image div {
    font-size: 100px
  }
}
</style>
