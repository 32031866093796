<template>
  <div
    id="details"
    class="details"
  >
    <div
      id="top-bar"
      class="nav-details"
    >
      <div>
        <!-- <div class="left">
          <font-awesome-icon
            class="prev"
            icon="fa-solid fa-chevron-right"
            @click="prevPort()"
          />
          <font-awesome-icon
            class="next"
            icon="fa-solid fa-chevron-right"
            @click="nextPort()"
          />
        </div> -->
        <div class="title">{{ data[id].title }}</div>
        <div class="right">
          <font-awesome-icon
            @click="closeModal"
            icon="fa-solid fa-xmark"
          />
        </div>
      </div>
    </div>
    <div class="content">
      <div v-if="data && data[id] && data[id].images">
        <SliderPortfolio :data="data[id].images">
        </SliderPortfolio>
        <!-- <div class="bottom-close">
          <font-awesome-icon
            @click="closeModal"
            icon="fa-solid fa-xmark"
          />
        </div> -->
        <div class="bottom-close">
          <div
            @click="closeModal"
            class="button-get-back"
          >
            RETOUR
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SliderPortfolio from '@/components/Images/SliderPortfolio.vue'

export default {
  name: 'Details',
  components: {
    SliderPortfolio
  },
  props: {
    data: Object,
    currentId: Number,
  },
  data() {
    return {
      isOpen: true,
      id: this.currentId,
      dataFiltred: null
    }
  },
  created: function () {
    window.addEventListener('keyup', this.listenKeyboard);
  },
  destroyed() {
    window.removeEventListener('keyup', this.listenKeyboard);
  },
  methods: {
    listenKeyboard: function (event) {
      const isEsc = event.keyCode === 27
      // const isLeft = event.keyCode === 37
      // const isRight = event.keyCode === 39

      if (isEsc) this.closeModal()
      // if (isLeft) this.prevPort()
      // if (isRight) this.nextPort()
    },
    closeModal() {
      this.$emit('closeModalFromChild', false)
    },
    prevPort() {
      if (this.data[this.id - 1]) {
        if (this.data[this.id - 1].isBigSize && this.data[this.id - 2]) {
          this.id = this.id - 2
          this.scrollToTop()
        } else if (!this.data[this.id - 1].isBigSize) {
          this.id--
          this.scrollToTop()
        }
      }
    },
    nextPort() {
      if (this.data[this.id + 1]) {
        if (this.data[this.id + 1].isBigSize && this.data[this.id + 2]) {
          this.id = this.id + 2
          this.scrollToTop()
        } else if (!this.data[this.id + 1].isBigSize) {
          this.id++
          this.scrollToTop()
        }
      }
    },
    scrollToTop() {
      const myDiv = document.getElementById('details');
      myDiv.scrollTop = 0;
    }
  }
}
</script>

<style lang="scss" scoped>
.details {
  overflow-y: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: rgb(239, 239, 239);
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 5;

  .nav-details {
    z-index: 2;
    width: 100%;
    height: 60px;
    position: fixed;
    padding: 20px 20px;
    box-sizing: border-box;
    background-color: var(--bg-color-light);
    display: flex;
    align-items: center;
    justify-content: left;

    >div {
      width: 100%;
      position: relative;

      display: flex;
      align-items: center;
      justify-content: left;

      .left {
        left: 0px;
        position: absolute;

        .prev {
          transform: rotate(180deg);
          margin-right: 20px;
          cursor: pointer;
        }

        .next {
          cursor: pointer;
        }
      }

      .title {
        margin: auto;
      }

      .right {
        right: 0px;
        position: absolute;

        cursor: pointer;
      }
    }
  }

  .content {
    width: 100%;
    margin: auto;
    padding: 120px 0px;
    box-sizing: border-box;

    img {
      max-width: 1000px;
      width: 100%;
    }

    .bottom-close {
      .button-get-back {
        display: inline-block;
        font-size: 11px;
        font-weight: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
        border: 2px solid;
        padding: 10px 30px 10px 30px;
        max-width: 100%;
        font-family: montserratregular, sans-serif;
        font-weight: normal;
        border-radius: 25px;
        border: 1px solid;
        color: var(--color-dark);
        border-color: var(--color-dark);
        background-color: transparent;
        text-decoration: none;
        cursor: pointer;
      }

      .button-get-back:hover {
        color: var(--color-light);
        background-color: var(--color-dark);
      }
    }
  }
}

@media (min-width: $mq-m) {
  .details {
    .content {
      padding: 120px 50px;
    }
  }
}
</style>