import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LegalView from '../views/LegalView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/legal',
    name: 'legal',
    component: LegalView
  },
  { path: '/:pathMatch(.*)*', component: HomeView },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // createWebHashHistory(), // 
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
  }
})


export default router
