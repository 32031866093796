<template>
  <div
    id="solutions"
    class="solutions"
  >
    <div>
      <h1>{{ data?.title }}</h1>
      <p style="white-space: break-spaces;">{{ data?.description }}</p>
    </div>

    <div class="solutions-items">
      <div class="solution">
        <div class="solution-content">
          <h2>{{ data?.solution[0]?.title }}</h2>
          <p style="white-space: break-spaces;">{{ data?.solution[0]?.description }}</p>
          <div
            v-if="data?.solution[0]?.description_list"
            class="special-content montserrat"
            style="white-space: break-spaces;"
          >{{ data?.solution[0]?.description_list }}</div>
          <div class="button-div">
            <a :href="data?.solution[0]?.bouton.href">{{ data?.solution[0]?.bouton.text }}</a>
          </div>
        </div>
      </div>
      <div class="solution">
        <div class="solution-content">
          <h2>{{ data?.solution[1]?.title }}</h2>
          <p style="white-space: break-spaces;">{{ data?.solution[1]?.description }}</p>
          <div
            v-if="data?.solution[1]?.description_list"
            class="special-content montserrat"
            style="white-space: break-spaces;"
          >{{ data?.solution[1]?.description_list }}</div>
          <div class="button-div">
            <a :href="data?.solution[1]?.bouton.href">{{ data?.solution[1]?.bouton.text }}</a>
          </div>
        </div>
        <div class="solution-content solution-content3">
          <h2>{{ data?.solution[2]?.title }}</h2>
          <p style="white-space: break-spaces;">{{ data?.solution[2]?.description }}</p>
          <div
            v-if="data?.solution[2]?.description_list"
            class="special-content montserrat"
            style="white-space: break-spaces;"
          >{{ data?.solution[2]?.description_list }}</div>
          <div class="button-div">
            <a :href="data?.solution[2]?.bouton.href">{{ data?.solution[2]?.bouton.text }}</a>
          </div>
        </div>
      </div>
      <div class="solution">
        <div class="solution-content">
          <h2>{{ data?.solution[3]?.title }}</h2>
          <p style="white-space: break-spaces;">{{ data?.solution[3]?.description }}</p>
          <div
            v-if="data?.solution[3]?.description_list"
            class="special-content montserrat"
            style="white-space: break-spaces;"
          >{{ data?.solution[3]?.description_list }}</div>
          <div class="button-div">
            <a :href="data?.solution[3]?.bouton.href">{{ data?.solution[3]?.bouton.text }}</a>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h5 class="montserrat">{{ data?.sub_description_1 }}</h5>
      <img
        :src="data?.sub_description_image?.url"
        alt=""
        loading="lazy"
      />
      <h2 class="montserrat">{{ data?.sub_description_2 }}</h2>
      <a
        class="montserrat"
        :href="data?.bouton?.href"
      >{{ data?.bouton?.text }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Solutions',
  props: {
    data: Object
  },
}
</script>

<style scoped lang="scss">
.solutions {
  background-color: var(--bg-color-beige);

  >div:first-child {
    text-align: left;
    padding: 30px 10px;

    p {
      font-size: 13px;
    }
  }

  >div:nth-child(2) {
    .solution {
      box-sizing: border-box;
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      min-height: 1px;
      float: left;
      padding-left: 15px;
      padding-right: 15px;
      position: relative;
      padding-bottom: 15px;
      padding-top: 15px;
      margin-bottom: 50px;
    }

    .solution-content {

      box-sizing: border-box;
      position: relative;
      border: solid thin #000;
      text-align: left;
      padding: 20px 20px 20px 20px;
      display: block;
      font-family: 'montserratregular';
      font-size: 13px;
      color: var(--color-blue);

      h2 {
        line-height: 1;
        margin-top: 0px;
        font-weight: normal;
        margin-bottom: 30px;
        font-size: 28px;
        letter-spacing: 1px;
        font-family: 'GrouchBT';
        color: var(--color-dark);
      }

      p {
        line-height: 24px;
        margin: auto auto 1.5em;
      }

      .special-content {
        line-height: 24px;
        color: var(--color-dark);
        padding-left: 60px;
        margin-bottom: 40px;
        margin-top: 70px;
        text-transform: uppercase;
      }

      .button-div {
        text-align: right;
        vertical-align: bottom;
        margin-bottom: 10px;
        position: relative;
        bottom: 15px;
        right: 25px;
        margin-top: 50px;
      }

      a {
        display: inline-block;
        font-size: 11px;
        font-weight: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
        border: 2px solid;
        padding: 10px 30px 10px 30px;
        max-width: 100%;
        font-family: montserratregular, sans-serif;
        font-weight: normal;
        border-radius: 25px;
        border: 1px solid;
        color: var(--color-dark);
        border-color: var(--color-dark);
        background-color: transparent;
        text-decoration: none;
      }

      a:hover {
        color: var(--color-light);
        background-color: var(--color-dark);
      }
    }

    .solution-content3 {
      margin-top: 65px;
    }
  }

  >div:nth-child(3) {
    h5 {
      font-size: 15px;
      margin: 50px 0 0 0;
    }

    img {
      margin: 30px 0px;
    }

    h2 {
      font-size: 22px;
      text-align: center;
      letter-spacing: 4px;
      margin: 0px 0 30px 0;
      color: var(--color-dark);
    }

    a {
      display: inline-block;
      font-size: 11px;
      font-weight: normal;
      letter-spacing: 1px;
      text-transform: uppercase;
      border: 2px solid;
      padding: 6px 37px 5px 40px;
      max-width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 40px;
      padding-right: 40px;
      background-color: #165887;
      color: white;
      border: none;
      font-weight: bold;
      cursor: pointer;
      border-radius: 25px !important;
      text-decoration: none;
      margin-bottom: 60px;
    }
  }
}

@media (min-width: 1100px) {
  .solutions {
    >div:first-child {
      margin: auto;
      text-align: left;
      max-width: 1200px;

    }

    >div:nth-child(2) {
      margin: auto;
      text-align: center;
      max-width: 1230px;

      .solution {
        box-sizing: border-box;
        width: 33.33%;
        min-height: 1px;
        float: left;
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
        padding-bottom: 15px;
        padding-top: 15px;
      }

      .solution-content .button-div {
        position: absolute;
      }

      .solution-content3 {
        margin-top: 0px;
      }

      .solution:nth-child(1) .solution-content,
      .solution:nth-child(3) .solution-content {
        min-height: 795px;
      }

      .solution:nth-child(2) .solution-content:nth-child(1) {
        min-height: 420px;
        margin-bottom: 35px;
      }

      .solution:nth-child(2) .solution-content:nth-child(2) {
        min-height: 340px;
      }
    }

    >div:nth-child(3) {
      margin: auto;
      text-align: center;
      max-width: 1200px;
    }
  }
}
</style>
