<template>
    <div
        class="slider-portfolio"
        v-if="data?.length"
    >
        <div>
            <Carousel
                :pagination="true"
                :draggable="true"
                :autoPlayTime="4000"
                :numberOfItems="data.length"
                :numberOfItemsToShow="1"
                :numberOfItemsToShowCustom="[[1, 1], [559, 1], [879, 1]]"
            >
                <Slide
                    v-for="item in data"
                    v-bind:key="item?.id"
                >
                    <img
                        v-if="item?.url"
                        :src="item?.url"
                        :alt="item?.name"
                    />
                </Slide>
            </Carousel>
        </div>
    </div>
</template>

<script>
import Slide from '@/components/Noice/Slide.vue'
import Carousel from '@/components/Noice/Carousel.vue'

export default {
    components: {
        Slide,
        Carousel
    },
    props: {
        data: Object
    }
}
</script>

<style lang="scss" scoped>
.slider-portfolio {
    .carousel {
        padding: 20px 0px;
        margin: 20px 0;
    }

    img {
        max-width: 100%;
    }
}

@media (min-width: $mq-m) {
    .slider-portfolio {
        .carousel {
            padding: 20px 10px;
            margin: 20px;
        }
        img {
            max-width: 1000px;
        }
    }
}
</style>