<template>
  <div class="home">
    <FullsizeImage :data="data?.accueil" />
    <TheStudio :data="data && data['le-studio']" />
    <Prestations :data="data?.prestation" />
    <Solutions :data="data?.solution" />
    <Portfolio :data="data?.portfolio" />
    <References :data="data?.reference" />
    <Contact :data="data?.contact" />
  </div>
</template>

<script>
// @ is an alias to /src
import FullsizeImage from '@/views/1_FullsizeImage.vue'
import TheStudio from '@/views/2_TheStudio.vue'
import Prestations from '@/views/3_Prestations.vue'
import Solutions from '@/views/4_Solutions.vue'
import Portfolio from '@/views/5_Portfolio.vue'
import References from '@/views/6_References.vue'
import Contact from '@/views/7_Contact.vue'

export default {
  name: 'HomeView',
  components: {
    FullsizeImage,
    TheStudio,
    Prestations,
    Solutions,
    Portfolio,
    References,
    Contact
  },
  props: {
    data: Object
  },
}
</script>
